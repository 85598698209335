import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _272fc440 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _0690cfb0 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _64e66884 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _19b1497a = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _968f2640 = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _08357d0b = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _0301a2fc = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _5abd8d8b = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _6fba241c = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _3bdc27a6 = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _375b1484 = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _2b08e519 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _5494f6c4 = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _89e5874c = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _09e58a4c = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _0745e412 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _535c8980 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _3703f92a = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _5ff682a2 = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _0cdd6887 = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _082e8406 = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _d0957d06 = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _5888ac2a = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _7818eefc = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _03e14d3c = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _04faf224 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _ecc803a8 = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _21f1eba7 = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _fee87096 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _68c3e3d6 = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _57652514 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _f52f33a8 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _1f547842 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _0fdc5738 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _0c555c68 = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _4b28cd52 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _0a18dfe7 = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _d5c21b16 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _2733f486 = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _6c4f9a57 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _6d5e29bf = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _44dafe90 = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _ac7e9dfc = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _7ff7bb16 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _e811dc4c = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _67dacbef = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _0fa3f642 = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _35dc9a45 = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _8151151e = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _1e89f2fc = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _1ec82f96 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _488f711a = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _afb1dcc8 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _a8d0c1de = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _34898b30 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _02d2ed46 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _47e3068e = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _e490be18 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _2bc4aa32 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _272fc440,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _272fc440,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _272fc440,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _272fc440,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _272fc440,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _272fc440,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _272fc440,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _272fc440,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _0690cfb0,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _64e66884,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _19b1497a,
    name: "account"
  }, {
    path: "/cart",
    component: _272fc440,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _968f2640,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _08357d0b,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _0301a2fc,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _5abd8d8b,
    name: "designs"
  }, {
    path: "/facets",
    component: _6fba241c,
    name: "facets"
  }, {
    path: "/favorites",
    component: _3bdc27a6,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _375b1484,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _2b08e519,
    name: "health"
  }, {
    path: "/not-found",
    component: _5494f6c4,
    name: "not-found"
  }, {
    path: "/proof",
    component: _89e5874c,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _09e58a4c,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _0745e412,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _535c8980,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _3703f92a,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _5ff682a2,
    name: "signin"
  }, {
    path: "/signout",
    component: _0cdd6887,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _082e8406,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _d0957d06,
    name: "testy"
  }, {
    path: "/version",
    component: _5888ac2a,
    name: "version"
  }, {
    path: "/version-app",
    component: _7818eefc,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _03e14d3c,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _04faf224,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _ecc803a8,
    name: "products-utils"
  }, {
    path: "/sso/facebook/delete",
    component: _21f1eba7,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _fee87096,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _68c3e3d6,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _0690cfb0,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _64e66884,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _57652514,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _f52f33a8,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _1f547842,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _0fdc5738,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _0c555c68,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _4b28cd52,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _0a18dfe7,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _d5c21b16,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _2733f486,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _6c4f9a57,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _6d5e29bf,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _44dafe90,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _ac7e9dfc,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _7ff7bb16,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _e811dc4c,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _67dacbef,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _0fa3f642,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _35dc9a45,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _8151151e,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _1e89f2fc,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _1ec82f96,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _488f711a,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _afb1dcc8,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _a8d0c1de,
    name: "orders-id-track-shipment"
  }, {
    path: "/products/:slug?/reviews",
    component: _34898b30,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _02d2ed46,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _47e3068e,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _e490be18,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _2bc4aa32,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
